import React from "react";
import { Link } from "gatsby";

export default function LetsTalkButton ({ className }) {
  return (
    <>
      <div className={`w-max ${className}`}>
      {process.env.GATSBY_WEBSTORE_URL ?
        <a href={`${process.env.GATSBY_WEBSTORE_URL}`} target="_blank" rel="noreferrer" className="flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-highlight hover:bg-opacity-70 sm:px-8">
          Purchase a Gift Card
        </a>
          :
        <Link to="/contact" className="flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-highlight hover:bg-opacity-70 sm:px-8">
          Let&rsquo;s&nbsp;Talk
        </Link>
      }
      </div>
    </>
  )
};

