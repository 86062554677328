import React from "react";
import { useState, useRef } from "react";
import { useClickOutside } from "@utilities/click-outside";
import { Link } from "gatsby";
import { Transition } from "@headlessui/react";

const Nav = ({ applepay }) => {
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);

  const solutionsRef = useRef(null);
  useClickOutside(solutionsRef, () => setIsSolutionsOpen(false));
  const supportRef = useRef(null);
  useClickOutside(supportRef, () => setIsSupportOpen(false));
  const aboutUsRef = useRef(null);
  useClickOutside(aboutUsRef, () => setIsAboutUsOpen(false));

  return (
    <nav className="hidden lg:flex space-x-10 items-center w-auto">
      {
          <div className="relative">
            <button type="button"
              onClick={() => { setIsAboutUsOpen(false); setIsSupportOpen(false); setIsSolutionsOpen(!isSolutionsOpen)}}
              className={`${isSolutionsOpen ? 'text-gray-900' : 'text-gray-700'} group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`} aria-expanded="false">
              <span>Solutions</span>
              <svg className={`${isSolutionsOpen ? 'text-gray-600' : 'text-gray-400'} ml-2 h-5 w-5 group-hover:text-gray-500`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>

            <Transition
              show={isSolutionsOpen}
              enter="ease-out"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {(ref) => (
                <div className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2" ref={solutionsRef}>
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">

                      <Link to="/solutions/shopping-centres" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Shopping Centres
                      </Link>
                      <Link to="/solutions/corporate" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Corporate
                      </Link>
                      <Link to="/solutions/government" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Government
                      </Link>


                    </div>

                    <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                      <div className="flow-root">
                        <a href="tel:1300764721" className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                          <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                          </svg>
                          <span className="ml-3">1300&nbsp;764&nbsp;721</span>
                        </a>
                      </div>

                      <div className="flow-root">
                        <a href="mailto:sales@waivpay.com" className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                          <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                          <span className="ml-3">sales@waivpay.com</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
      }

      {
          <div className="relative">
            <button type="button"
              onClick={() => {  setIsAboutUsOpen(false); setIsSupportOpen(!isSupportOpen); setIsSolutionsOpen(false)}}
              className={`${isSupportOpen ? 'text-gray-900' : 'text-gray-700'} group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`} aria-expanded="false">
              <span>Support</span>
              <svg className={`${isSupportOpen ? 'text-gray-600' : 'text-gray-400'} ml-2 h-5 w-5 group-hover:text-gray-500`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>

            <Transition
              show={isSupportOpen}
              enter="ease-out"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {(ref) => (
                <div className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0" ref={supportRef}>
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 grid-cols-2 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <Link to="/contact" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Support Contact
                      </Link>
                      <Link to="/balance-check" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Balance Check
                      </Link>
                      <Link to="/privacy" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Privacy
                      </Link>
                      <Link to="/terms" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Terms & Conditions
                      </Link>
                      <a href={process.env.GATSBY_EXTERNAL_FAQS_URL} target="_blank" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        FAQ
                      </a>
                    </div>
                    <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                      <div className="flow-root">
                        <a href="tel:1300764721" className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                          <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                          </svg>
                          <span className="ml-3">1300&nbsp;764&nbsp;721</span>
                        </a>
                      </div>

                      <div className="flow-root">
                        <a href="mailto:support@waivpay.com" className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                          <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                          <span className="ml-3">support@waivpay.com</span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              )}
            </Transition>
          </div>
      }


      {
          <div className="relative">
            <button type="button"
              onClick={() => { setIsAboutUsOpen(!isAboutUsOpen); setIsSupportOpen(false); setIsSolutionsOpen(false) }}
              className={`${isAboutUsOpen ? 'text-gray-900' : 'text-gray-700'} group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`} aria-expanded="false">
              <span>About&nbsp;Us</span>
              <svg className={`${isAboutUsOpen ? 'text-gray-600' : 'text-gray-400'} ml-2 h-5 w-5 group-hover:text-gray-500`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>

            <Transition
              show={isAboutUsOpen}
              enter="ease-out"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {(ref) => (
                <div className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0" ref={aboutUsRef}>
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 grid-cols-2 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <Link to="/about" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Company
                      </Link>
                      <Link to="/about/board" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Board
                      </Link>
                      <Link to="/about/team" className="text-base font-medium text-gray-900 hover:text-gray-700">
                        Team
                      </Link>
                    </div>
                    <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                      <div className="flow-root">
                        <a href="tel:1300764721" className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                          <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                          </svg>
                          <span className="ml-3">1300&nbsp;764&nbsp;721</span>
                        </a>
                      </div>

                      <div className="flow-root">
                        <a href="mailto:support@waivpay.com" className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                          <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                          <span className="ml-3">support@waivpay.com</span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              )}
            </Transition>
          </div>
      }

      {
          <Link to="/locations" className="group round-md text-base font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Locations
          </Link>
      }

      {
          <Link to="/blog" className="group round-md text-base font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            News
          </Link>
      }

      {
        applepay && process.env.GATSBY_APPLEPAY === 'true' &&
          <Link to="/applepay" className="group round-md text-base font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Apple&nbsp;Pay
          </Link>
      }

    </nav>
  );
}

export default Nav;
