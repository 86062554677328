import React from "react";
import BalanceCheckButton from "@components/BalanceCheckButton";
import LetsTalkButton from "@components/LetsTalkButton";

const NavButtons = ({ className }) => {
  return (
    <nav className="hidden lg:flex space-x-2 items-center w-auto">
      <BalanceCheckButton className={className} />
      <LetsTalkButton className={className}/>
    </nav>
  )
}

export default NavButtons;
