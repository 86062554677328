import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import ImageTag from "gatsby-image";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Logo = ({ className }) => {
  const images = useStaticQuery(graphql`
    query {
      mobileLogo: file(name: { eq: "waivpay-logo" }) {
        sharp: childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      logo: file(name: { eq: "waivpay-logo" }) {
        sharp: childImageSharp {
          fixed(width: 280) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.mobileLogo.sharp.fixed,
    {
      ...images.logo.sharp.fixed,
      media: `(min-width: 640px)`,
    },
  ];

  return (
    <Link to="/"
      className={classNames(
        className,
        "flex items-center -ml-3 w-full"
      )}
    >
      <ImageTag Tag="div" fixed={sources} />
    </Link>
  );
}

export default Logo;

