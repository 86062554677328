import React from "react";
import { useState } from 'react';
import { graphql, useStaticQuery, Link } from "gatsby";
import ImageTag from "gatsby-image";
import { Transition } from '@headlessui/react';

const Drawer = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(name: { eq: "waivpay-logo" }) {
        sharp: childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const [isMobileOpen, setIsMobileOpen] = useState(false);

  return (
    <div className="-my-2 lg:hidden">
      <button type="button"
        onClick={() => setIsMobileOpen(!isMobileOpen)}
        className="bg-white bg-opacity-60 rounded-md p-3 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-highlight" aria-expanded="false">
        <span className="sr-only">Open menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>

      <Transition
        show={isMobileOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <Link to="/">
                  <ImageTag Tag="div" className="-ml-2 mt-1" fixed={logo.sharp.fixed} />
                </Link>
                <div className="-mr-2">
                  <button type="button"
                    onClick={() => setIsMobileOpen(!isMobileOpen)}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-highlight">
                    <span className="sr-only">Close menu</span>
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Solutions
                  </h3>

                  <Link to="/solutions/shopping-centres" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                    <span className="ml-3 text-base font-medium text-gray-900">
                      Shopping Centres
                    </span>
                  </Link>

                  <Link to="/solutions/corporate" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                    <span className="ml-3 text-base font-medium text-gray-900">
                      Corporate
                    </span>
                  </Link>

                  <Link to="/solutions/government" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                    <span className="ml-3 text-base font-medium text-gray-900">
                      Government
                    </span>
                  </Link>
                </nav>
              </div>
            </div>

            <div className="py-6 px-5 space-y-6">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Support
              </h3>
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link to="/balance-check" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Balance&nbsp;Check
                </Link>

                <Link to="/locations" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Locations
                </Link>

                <Link to="/contact" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Contact
                </Link>

                <Link to="/about" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  About
                </Link>

                <Link to="/terms" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Terms
                </Link>

                <Link to="/privacy" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Privacy
                </Link>

                <Link to="/about/board" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Board
                </Link>

                <Link to="/about/team" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default Drawer;

