import React from "react";
import { Link } from "gatsby";

export default function BalanceCheckButton({ className }) {
  return (
    <div className={`w-max ${className}`}>
      <Link to="/balance-check" className="flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary bg-opacity-80 hover:bg-opacity-70 sm:px-8">
        Balance&nbsp;Check
      </Link>
    </div>
  )
};
